<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" style="min-width: 100px">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <SelectCustomer
                :label="$t('labels.customer')"
                :placeholder="$t('labels.customer')"
                name="id_customer"
                sort-name="company_name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.goods_name')"
                :placeholder="$t('labels.goods_name')"
                name="name"
                sort-name="name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.goods_description')"
                :placeholder="$t('labels.goods_description')"
                name="description"
                sort-name="description"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.size')"
                :placeholder="$t('labels.size')"
                name="size"
                sort-name="size"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <SelectFilter
                :options="imageOptions"
                :label="$t('labels.image')"
                :placeholder="$t('labels.image')"
                name="image"
                sort-name="url_images"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.sku')"
                :placeholder="$t('labels.sku')"
                name="sku"
                sort-name="sku"
                :default-value="filters.sku"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')"
                name="customer_goods_barcode"
                :default-value="filters.customer_goods_barcode"
                sort-name="customer_goods_barcode"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.sub_barcode')"
                :placeholder="$t('labels.sub_barcode')"
                name="sub_barcode"
                sort-name="sub_barcode"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.category')"
                :placeholder="$t('labels.category')"
                name="category_name"
                sort-name="category_name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilter
                :label="$t('labels.position')"
                :placeholder="$t('labels.position')"
                name="storage_location"
                sort-name="storage_location"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <!--<th role="columnheader" style="min-width: 100px">
            <SelectFilter :options="transportOptions" label="PP. Giao" placeholder="PP. Giao" name="transport" sort-name="transport" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange"/>
          </th>-->
            <th role="columnheader" style="min-width: 100px">
              <InputFilterFromTo
                :label="$t('labels.receiving')"
                :placeholder="$t('labels.receiving')"
                name="receiving"
                sort-name="receiving"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilterFromTo
                :label="$t('labels.stowing')"
                :placeholder="$t('labels.stowing')"
                name="stowing"
                sort-name="stowing"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilterFromTo
                :label="$t('labels.wait_pickup')"
                :placeholder="$t('labels.wait_pickup')"
                name="wait_pickup"
                sort-name="wait_pickup"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilterFromTo
                :label="$t('labels.pickedup')"
                :placeholder="$t('labels.pickedup')"
                name="pickedup"
                sort-name="pickedup"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilterFromTo
                :label="$t('labels.available')"
                :placeholder="$t('labels.available')"
                name="available"
                sort-name="available"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilterFromTo
                :label="$t('labels.reserved')"
                :placeholder="$t('labels.reserved')"
                name="reserved"
                sort-name="reserved"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="min-width: 100px">
              <InputFilterFromTo
                :label="$t('labels.returning')"
                :placeholder="$t('labels.returning')"
                name="returning"
                sort-name="returning"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <template v-if="['view1', 'view4'].includes(viewOption)">
              <th role="columnheader" style="min-width: 100px">
                <InputFilter
                  :label="$t('labels.total_volume_split')"
                  :placeholder="$t('labels.total_volume_split')"
                  name="total_volume_split"
                  sort-name="total_volume_split"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="min-width: 100px">
                <DateRangeFilter
                  :label="$t('labels.sell_volume')"
                  :placeholder="$t('labels.sell_volume')"
                  sort-name="data_date"
                  has-sort
                  :default-date="defaultDate()"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="min-width: 100px">
                <InputFilter
                  :label="$t('labels.forecast_out_of_stock')"
                  :placeholder="$t('labels.forecast_out_of_stock')"
                  name="forecast_out_of_stock"
                  sort-name="forecast_out_of_stock"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
            </template>
            <template v-if="['view2', 'view4'].includes(viewOption)">
              <th role="columnheader" style="min-width: 100px">
                <InputFilterFromTo
                  :label="$t('labels.d_size')"
                  :placeholder="$t('labels.d_size')"
                  name="d_size"
                  sort-name="d_size"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="min-width: 100px">
                <InputFilterFromTo
                  :label="$t('labels.r_size')"
                  :placeholder="$t('labels.r_size')"
                  name="r_size"
                  sort-name="r_size"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="min-width: 100px">
                <InputFilterFromTo
                  :label="$t('labels.c_size')"
                  :placeholder="$t('labels.c_size')"
                  name="c_size"
                  sort-name="c_size"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="min-width: 100px">
                <InputFilterFromTo
                  :label="$t('labels.pre_weight')"
                  :placeholder="$t('labels.pre_weight')"
                  name="pre_weight"
                  sort-name="pre_weight"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="min-width: 100px">
                <InputFilterFromTo
                  :label="$t('labels.weight')"
                  :placeholder="$t('labels.weight')"
                  name="weight"
                  sort-name="weight"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
            </template>
            <template v-if="['view3', 'view4'].includes(viewOption)">
              <th role="columnheader" style="min-width: 100px">
                <InputFilterFromTo
                  :label="$t('labels.cost_custom_import')"
                  :placeholder="$t('labels.cost_custom_import')"
                  name="cost_custom_import"
                  sort-name="cost_custom_import"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="min-width: 100px">
                <InputFilterFromTo
                  :label="$t('labels.cost_custom_store')"
                  :placeholder="$t('labels.cost_custom_store')"
                  name="cost_custom_store"
                  sort-name="cost_custom_store"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="min-width: 100px">
                <InputFilterFromTo
                  :label="$t('labels.cost_custom_export')"
                  :placeholder="$t('labels.cost_custom_export')"
                  name="cost_custom_export"
                  sort-name="cost_custom_export"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="min-width: 100px">
                <InputFilterFromTo
                  :label="$t('labels.wholesale_price')"
                  :placeholder="$t('labels.wholesale_price')"
                  name="wholesale_price"
                  sort-name="wholesale_price"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="min-width: 100px">
                <InputFilterFromTo
                  :label="$t('labels.goods_price')"
                  :placeholder="$t('labels.goods_price')"
                  name="price"
                  sort-name="price"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="min-width: 100px">
                <InputFilterFromTo
                  :label="$t('labels.insurance_value')"
                  :placeholder="$t('labels.insurance_value')"
                  name="insurance_value"
                  sort-name="insurance_value"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="min-width: 100px">
                <SelectFilter
                  :options="yesNoOptions"
                  :label="$t('labels.uid_printed_on_item')"
                  :placeholder="$t('labels.uid_printed_on_item')"
                  name="uid_printed_on_item"
                  sort-name="uid_printed_on_item"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="min-width: 100px">
                <InputFilter
                  :label="$t('labels.note')"
                  :placeholder="$t('labels.note')"
                  name="comment"
                  sort-name="comment"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="`${item.id}_${index}`"
            class="text-center"
          >
            <td>{{ item.warehouse_code }}</td>
            <td>{{ item.company_name }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.size }}</td>
            <td>
              <ImageViewer v-if="item.url_images" :url="item.url_images" />
            </td>
            <td><SkuDownload :item="item" @onDbClick="onDbClick" /></td>
            <td>
              <span
                class="primary--text cursor-pointer text-decoration-underline"
                v-if="
                  checkPermission([
                    'goods_report_size_edit',
                    'goods_report_cost_edit',
                  ])
                "
                @click="showPropertyDialog(item)"
              >
                {{ item.customer_goods_barcode }}
              </span>
              <span v-else>{{ item.customer_goods_barcode }}</span>
              <br />
              <small
                class="grey--text font-italic"
                :title="$t('labels.first_employee_handle')"
                >{{ item.first_employee_handle || "" }}</small
              >
            </td>
            <td>{{ item.sub_barcode || "-" }}</td>
            <td>{{ item.category_name }}</td>
            <td>
              <div v-html="getStorageLocation(item.storage_location)"></div>
            </td>
            <td>{{ formatNumber(item.receiving) }}</td>
            <td>{{ formatNumber(item.stowing) }}</td>
            <td>{{ formatNumber(item.wait_pickup) }}</td>
            <td>{{ formatNumber(item.pickedup) }}</td>
            <td>{{ formatNumber(item.available) }}</td>
            <td>{{ formatNumber(item.reserved) }}</td>
            <td>{{ formatNumber(item.returning) }}</td>
            <template v-if="['view1', 'view4'].includes(viewOption)">
              <td>{{ formatNumber(item.total_volume_split * 100) }}%</td>
              <td>{{ item.handover_new }}</td>
              <td>
                {{
                  item.handover_new > 0
                    ? formatNumber(item.available / item.handover_new)
                    : 0
                }}
              </td>
            </template>
            <template v-if="['view2', 'view4'].includes(viewOption)">
              <td>{{ item.d_size || "-" }}</td>
              <td>{{ item.r_size || "-" }}</td>
              <td>{{ item.c_size || "-" }}</td>
              <td>{{ item.pre_weight || "-" }}</td>
              <td>
                {{
                  (item.use_pre_weight ? item.pre_weight : item.weight) || "-"
                }}
              </td>
            </template>
            <template v-if="['view3', 'view4'].includes(viewOption)">
              <td>
                {{
                  item.cost_custom_import
                    ? formatNumber(item.cost_custom_import)
                    : "-"
                }}
              </td>
              <td>
                {{
                  item.cost_custom_store
                    ? formatNumber(item.cost_custom_store)
                    : "-"
                }}
              </td>
              <td>
                {{
                  item.cost_custom_export
                    ? formatNumber(item.cost_custom_export)
                    : "-"
                }}
              </td>
              <td>
                {{
                  item.wholesale_price
                    ? formatNumber(item.wholesale_price)
                    : "-"
                }}
              </td>
              <td>{{ item.price ? formatNumber(item.price) : "-" }}</td>
              <td>
                {{
                  item.insurance_value
                    ? formatNumber(item.insurance_value)
                    : "-"
                }}
              </td>
              <td>{{ item.uid_printed_on_item ? "Yes" : "No" }}</td>
              <td>{{ item.comment || "-" }}</td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="pt-3">
      <v-col cols="12" sm="4">
        <div class="d-flex table-footer-count">
          <div class="mr-2">
            {{ $t("labels.sku") }}: <b>{{ formatNumber(sum.sumSku) }}</b>
          </div>
          <div class="mr-2">
            Avai. SKU: <b>{{ formatNumber(sum.sumAvaiSku) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.available") }}:
            <b>{{ formatNumber(sum.sumAvailable) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.reserved") }}:
            <b>{{ formatNumber(sum.sumReserved) }}</b>
          </div>
        </div>
        <div class="d-flex table-footer-count mt-1">
          <div class="mr-2">
            {{ $t("labels.receiving") }}:
            <b>{{ formatNumber(sum.sumReceiving) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.returning") }}:
            <b>{{ formatNumber(sum.sumReturning) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.stowing") }}:
            <b>{{ formatNumber(sum.sumStowing) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.wait_pickup") }}:
            <b>{{ formatNumber(sum.sumWaitPickup) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.pickedup") }}:
            <b>{{ formatNumber(sum.sumPickedup) }}</b>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="d-flex">
          <a
            :href="`/v2/goods-receipt/list?sku=${filters.sku || ''}&barcode=${
              filters.customer_goods_barcode || ''
            }&`"
            target="_blank"
            v-if="checkPermission(['goods_receipt_manager'])"
            class="mr-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default warning"
            style="height: 40px"
          >
            {{ $t("menus.goods_receipt_manager") }}
          </a>
          <a
            :href="`/v2/goods/uid?keywords=${filters.sku || ''}&barcode=${
              filters.customer_goods_barcode || ''
            }&`"
            target="_blank"
            v-if="checkPermission(['goods_uid_manager'])"
            class="mr-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary"
            style="height: 40px"
          >
            {{ $t("labels.uid") }}
          </a>
          <v-btn
            color="success"
            @click="reportExport"
            class="mr-2"
            style="height: 40px"
            ><v-icon>mdi-download</v-icon> Excel</v-btn
          >
          <v-text-field
            dense
            outlined
            hide-details
            v-model.number="page"
            :label="$t('labels.page')"
            :placeholder="$t('labels.page')"
            class="mr-2"
          ></v-text-field>
          <v-autocomplete
            dense
            outlined
            hide-details
            v-model="viewOption"
            :items="viewOptions"
            label="View mode"
            placeholder="View mode"
            @change="getList"
          ></v-autocomplete>
          <!--<v-btn color="secondary" style="height: 40px" @click="setViewOption('view0')" v-if="['view1'].includes(viewOption)"><v-icon>mdi-arrow-collapse</v-icon> Rút gọn</v-btn>
          <v-btn color="primary" style="height: 40px" @click="setViewOption('view1')" v-if="['view0'].includes(viewOption)"><v-icon>mdi-arrow-expand</v-icon> Đầy đủ</v-btn>-->
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="propertyDialog" persistent max-width="800px">
      <UpdateProperty
        v-if="propertyDialog"
        :item="updatingItem"
        @cancel="hidePropertyDialog"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="costDialog" persistent max-width="800px">
      <UpdateCost
        v-if="costDialog"
        :item="updatingItem"
        @cancel="hideCostDialog"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="uidDialog" persistent fullscreen>
      <v-card>
        <v-card-title class="text-h6">
          {{ $t("menus.goods_uid_manager") }}
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="hideUidDialog">
            {{ $t("labels.close") }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <UidTable v-if="uidDialog" :sku="uidSku || filters.sku" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import list from "@/components/goods/mixins/list";

export default {
  name: "ListDesktop",
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectCustomer: () => import("@/components/table/SelectCustomer"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    SkuDownload: () => import("@/components/goods/SkuDownload"),
    ImageViewer: () => import("@/components/goods/ImageViewer"),
    UpdateProperty: () => import("@/components/goods/UpdateProperty"),
    UpdateCost: () => import("@/components/goods/UpdateCost"),
    UidTable: () => import("@/components/goods/Uid"),
  },
  mixins: [list],
  watch: {
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
